/*
 * @File: Do not edit
 * @Date: 2021-02-19 13:37:55
 * @LastEditTime: 2023-11-23 17:06:05
 * @LastEditors: wwl2@meitu.com
 * @Description: 
 * @FilePath: /mh-xzs/src/page_error/index.jsx
 */
import React from 'react';
import './index.less'
 export default function PageError(props){
  // console.log(props,'props')
    const pageReLoad = () => {
      try {
        let url = props.location.pathname
        props.history.replace({
          pathname: url
        })
      } catch (error) {
          console.log(error)
          window.location.reload();
      }
    }

   return(
     <div className='page-error'>
       <img src={require('../styles/images/page_err/icon_no_network@2x.png')} alt=""/>
       <p>加载失败，请检查网络</p>
       <div className='btn' onClick={pageReLoad}>
          重新加载
       </div>
     </div>
   )
 }
