/*
 * @File: Do not edit
 * @Date: 2023-11-14 16:30:58
 * @LastEditTime: 2023-12-21 19:15:39
 * @LastEditors: wwl2@meitu.com
 * @Description:
 * @FilePath: /my-app/src/App.jsx
 */
import "./App.less";
import React, { useEffect } from "react";
import { Provider } from "mobx-react";
import { RenderRoute } from "./routers/index";
import stores from "./stores";

import MediaQuery from "react-responsive";
import Header from "./header/index";

function App() {
  useEffect(() => {
    stores.globalStore.updateStore();
  }, []);
  return (
    <>
      <Provider {...stores}>
        <div className="app">
          <RenderRoute>
            <MediaQuery minDeviceWidth={1224}>
              <Header isPC={true} />
            </MediaQuery>
            <MediaQuery maxDeviceWidth={1223}>
              <Header isPC={false} />
            </MediaQuery>
          </RenderRoute>
          <div className="app-footer">
            <a href="https://beian.miit.gov.cn/" target="_blanck">
              豫ICP备2024044384号
            </a>
          </div>
        </div>
      </Provider>
    </>
  );
}

export default App;
