/*
 * @File: Do not edit
 * @Date: 2021-02-19 13:37:55
 * @LastEditTime: 2023-11-14 17:51:49
 * @LastEditors: wwl2@meitu.com
 * @Description: 
 * @FilePath: /mh-xzs/src/components/Loading.jsx
 */
import React from 'react';
import './Loading.less';
import PageError from '../page_error'

export const Loading = ({error, pastDelay}) => {
    if (error) {
        // Toast.fail('error', 3, null, false);
        console.error(error)
        return <PageError />;
    } else if (pastDelay) {
        return <div className="circle-loading"></div>;
    } else {
        return null;
    }
};

