/*
 * @File: Do not edit
 * @Date: 2023-11-14 17:04:08
 * @LastEditTime: 2023-12-21 19:17:05
 * @LastEditors: wwl2@meitu.com
 * @Description:
 * @FilePath: /my-app/src/routers/index.jsx
 */

import React from "react";
import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import loadable from "react-loadable";
import { Loading } from "../components/Loading";
import Page404 from "../views/404";

const LOAD_TIME_OUT = 10 * 1000;
const DELAY_TIME = 3 * 100;
const loadingConf = {
  loading: Loading,
  delay: DELAY_TIME,
  timeout: LOAD_TIME_OUT,
};

const HomePage = loadable({
  loader: () => import("../home/index.jsx"),
  ...loadingConf,
});

const MHToolsPage = loadable({
  loader: () => import("../mh-tools/index.jsx"),
  ...loadingConf,
});

const ITToolsPage = loadable({
  loader: () => import("../it-tools/index.jsx"),
  ...loadingConf,
});

const UDIDPage = loadable({
  loader: () => import("../it-tools/udid/index.jsx"),
  ...loadingConf,
});

const QRCodePage = loadable({
  loader: () => import("../it-tools/QRcode/index.jsx"),
  ...loadingConf,
});

const TimeStampPage = loadable({
  loader: () => import("../it-tools/time_stamp/index.jsx"),
  ...loadingConf,
});

const JsonPage = loadable({
  loader: () => import("../it-tools/json/index.jsx"),
  ...loadingConf,
});

const routeConfig = {
  basename: "",
  list: [
    {
      path: "/404",
      component: Page404,
      exact: false,
    },
    {
      // 首页
      path: "/",
      component: HomePage,
      exact: true,
    },
    {
      path: "/mh-tools",
      component: MHToolsPage,
      exact: true,
    },
    {
      path: "/it-tools",
      component: ITToolsPage,
      exact: true,
    },
    {
      path: "/it-tools-udid",
      component: UDIDPage,
      exact: true,
    },
    {
      path: "/it-tools-QRcode",
      component: QRCodePage,
      exact: true,
    },
    {
      path: "/it-tools-time-stamp",
      component: TimeStampPage,
      exact: true,
    },
    {
      path: "/it-tools-json",
      component: JsonPage,
      exact: true,
    },
  ],
};
export function RenderRoute(props) {
  const { children } = props;
  const { list } = routeConfig;
  return (
    <div className="router-view">
      <Router>
        <Switch>
          {list.map((item, index) => {
            return item.exact ? (
              <Route
                path={item.path}
                key={item.path}
                component={item.component}
                exact
              />
            ) : (
              <Route
                path={item.path}
                key={item.path}
                component={item.component}
              />
            );
          })}
          {/* <Redirect to='/404' /> */}
        </Switch>
        {children}
      </Router>
    </div>
  );
}
