/*
 * @File: Do not edit
 * @Date: 2023-11-29 17:02:33
 * @LastEditTime: 2023-12-25 11:49:24
 * @LastEditors: wwl2@meitu.com
 * @Description:
 * @FilePath: /my-app/src/header/index.jsx
 */
import { Col, Row, Menu, Flex } from "antd";
import { Link } from "react-router-dom";
import {
  AppstoreFilled,
  ToolOutlined,
  PhoneFilled,
  WechatFilled,
  MessageFilled,
} from "@ant-design/icons";
import React, { useState } from "react";
import Blur from "react-blur";
import Logo from "./logo.png";
import BGImage from "./bg.jpeg";
import "./index.less";

const nav_items_pc = [
  {
    label: "马老板定制",
    key: "mh",
    icon: <AppstoreFilled />,
    children: [
      {
        label: <Link to="/mh-tools">梦幻小助手</Link>,
        key: "mh-tools",
      },
    ],
  },
  {
    label: "IT小助手",
    key: "it",
    icon: <ToolOutlined />,
    children: [
      {
        label: <Link to="/it-tools-udid">获取UDID</Link>,
        key: "udid",
      },
      {
        label: <Link to="/it-tools-QRcode">生成二维码</Link>,
        key: "qrcode",
      },
      {
        label: <Link to="/it-tools-time-stamp">在线时间戳转换</Link>,
        key: "time-stamp",
      },
      {
        label: <Link to="/it-tools-json">在线json解析工具</Link>,
        key: "json",
      },
    ],
  },
  {
    label: "联系我",
    key: "call",
    icon: <MessageFilled />,
    children: [
      {
        type: "group",
        label: "微信",
        children: [
          {
            label: "wwl0224",
            icon: <WechatFilled />,
            key: "wx",
          },
        ],
      },
      // {
      //   type: "group",
      //   label: "电话",
      //   children: [
      //     {
      //       label: "17600910511",
      //       icon: <PhoneFilled />,
      //       key: "phone",
      //     },
      //   ],
      // },
    ],
  },
];

const nav_items = [
  {
    // label: "马老板定制",
    key: "mh",
    icon: <AppstoreFilled />,
    children: [
      {
        label: <Link to="/mh-tools">梦幻小助手</Link>,
        key: "mh-tools",
      },
    ],
  },
  {
    // label: "IT小助手",
    key: "it",
    icon: <ToolOutlined />,
    children: [
      {
        label: <Link to="/it-tools-udid">获取UDID</Link>,
        key: "udid",
      },
      {
        label: <Link to="/it-tools-QRcode">生成二维码</Link>,
        key: "qrcode",
      },
      {
        label: <Link to="/it-tools-time-stamp">在线时间戳转换</Link>,
        key: "time-stamp",
      },
      {
        label: <Link to="/it-tools-json">在线json解析工具</Link>,
        key: "json",
      },
    ],
  },
  {
    // label: "联系我",
    key: "call",
    icon: <MessageFilled />,
    children: [
      {
        type: "group",
        label: "微信",
        children: [
          {
            label: "wwl0224",
            icon: <WechatFilled />,
            key: "wx",
          },
        ],
      },
      // {
      //   type: "group",
      //   label: "电话",
      //   children: [
      //     {
      //       label: "17600910511",
      //       icon: <PhoneFilled />,
      //       key: "phone",
      //     },
      //   ],
      // },
    ],
  },
];
function Header(props) {
  const { isPC } = props;
  // const [currentKey, setCurrentKey] = useState("");
  // function menuItemClick(e) {
  //   setCurrentKey(e.key);
  // }

  return (
    <header
      className="home-header"
      style={{
        backgroundColor: "transparent",
        height: "50px",
      }}
    >
      <Blur
        className="home-header-blur"
        img={BGImage}
        blurRadius={30}
        enableStyles
        style={{
          position: "fixed",
          top: "0",
          width: "100vw",
          height: "50px",
          opacity: "0.88",
        }}
      />
      <Row
        style={{
          backgroundColor: "transparent",
          height: "50px",
        }}
      >
        <Col span={isPC ? 2 : 1}></Col>
        <Col span={isPC ? 2 : 3}>
          <Flex justify="center" align="center">
            <a className="logo" href="/">
              <img
                style={{
                  marginTop: "10px",
                  height: "30px",
                }}
                src={Logo}
                alt="logo"
              />
              {/* <span
                style={{
                  color: "pink",
                  fontSize: "25px",
                }}
              >
                猛犸象
              </span> */}
            </a>
          </Flex>
        </Col>
        <Col span={18}>
          <Menu
            style={{
              backgroundColor: "transparent",
              height: "50px",
            }}
            theme="dark"
            mode="horizontal"
            selectedKeys={[]}
            // onClick={menuItemClick}
            items={isPC ? nav_items_pc : nav_items}
          />
        </Col>
      </Row>
    </header>
  );
}
export default Header;
