/*
 * @File: Do not edit
 * @Date: 2023-11-14 16:59:21
 * @LastEditTime: 2023-12-12 10:56:44
 * @LastEditors: wwl2@meitu.com
 * @Description: 
 * @FilePath: /my-app/src/stores/global.js
 */
import { observable, action } from 'mobx';

export class RootStore {
  constructor() {
    this.globalStore = new GlobalStore(this);
  }
}

export class GlobalStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.state = observable({
      userInfo: {
        isLogin: window._mtmxGlobalInfo?.isLogin,
      },
      show_super_modal: false, // 展示写心得弹窗
      activity_id: '', // 活动id
    });
  }

  // update actions
  updateStore = (key, data) => {
    this.state[key] = data;
  };
}