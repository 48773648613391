import React from 'react';
import './index.less';
import { Button, Space } from 'antd';

export default function Page404(props) {
    // console.log('[404] %o', props, props.history.action)
    return <div className="page page-404">
        <h1>404</h1>
        <p>您访问的页面不存在呀...</p>
        <Space size="lg"/>
        <Button type="primary" inline size="small" onClick={props.history.goBack}>返回上一页</Button>
    </div>
}
