/*
 * @File: File Content
 * @Author: wwl2@meitu.com
 * @Date: 2021-08-04 10:03:58
 */
import { GlobalStore } from './global';

export default {
  globalStore: new GlobalStore(),
};
