/*
 * @File: Do not edit
 * @Date: 2023-11-14 16:30:58
 * @LastEditTime: 2023-12-12 11:21:03
 * @LastEditors: wwl2@meitu.com
 * @Description:
 * @FilePath: /my-app/src/index.js
 */
import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <div id="check" className="check"></div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
